import React, { forwardRef, useRef, useImperativeHandle, Ref } from 'react';
import { InputProps, Input as AntdInput, InputRef as AntdInputRef } from 'antd';

import * as S from './styles';

type Props = {
  optionalText?: string;
} & InputProps;

export type InputRef = {
  focus: () => void;
};

export const Input = forwardRef(
  ({ size = 'large', optionalText, ...props }: Props, ref?: Ref<InputRef>) => {
    const inputRef = useRef<AntdInputRef>(null);
    useImperativeHandle(ref, () => ({
      focus: () => inputRef.current?.focus(),
    }));

    const numberValidatorProp = (e: React.KeyboardEvent) => {
      props.type === 'number' &&
        ['e', 'E', '+', '-'].includes(e.key) &&
        e.preventDefault();
    };

    return (
      <div>
        <AntdInput
          {...props}
          onKeyDown={numberValidatorProp}
          size={size}
          ref={inputRef}
        />
        {optionalText && <S.Hint type="secondary">{optionalText}</S.Hint>}
      </div>
    );
  }
);
