import React, { ReactNode, useState } from 'react';
import * as S from './styles';

type Props = React.PropsWithChildren<{
  label?: string | ReactNode;
  value?: string;
}>;

export const FloatLabel: React.FC<Props> = ({ label, value, children }) => {
  const [focus, setFocus] = useState(false);

  const labelClass =
    focus || (value && value?.length !== 0) ? 'label label-float' : 'label';

  return (
    <S.MinimalInput
      className="float-label"
      onBlur={() => setFocus(false)}
      onFocus={() => setFocus(true)}
    >
      {children}
      <label className={labelClass}>{label}</label>
    </S.MinimalInput>
  );
};
