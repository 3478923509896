import styled, { css } from 'styled-components';
import { Form } from '../Form';

const { Item } = Form;

export const FormItem = styled(Item)<{
  $hasToolTip?: boolean;
  $redLabelOnError?: boolean;
}>`
  display: inline;

  font-size: 12px;
  font-weight: 600;
  line-height: 1;
  margin-bottom: 0;

  ${({ $redLabelOnError }) =>
    $redLabelOnError &&
    css`
      &.ant-form-item-has-error .ant-form-item-label > label {
        color: #ff4d4f;
      }
    `}
  &
    .ant-form-item-label {
    display: block;
    padding-bottom: 0;
    white-space: unset;
    text-align: left;
    line-height: 1.6;

    > label {
      height: fit-content;
      margin-top: 5px;
      margin-bottom: 5px;
    }

    > label::after {
      display: none;
    }
  }

  &
    .ant-form-item-label
    > label.ant-form-item-required:not(
      .ant-form-item-required-mark-optional
    )::before {
    display: none;
  }

  & .ant-form-item-control {
    width: 100%;
  }

  .ant-form-item-with-help .ant-form-item-explain {
    min-height: 0px;
  }

  .ant-form-item-explain-error {
    font-size: 10px;
  }

  &.ant-form-item-has-error .ant-input {
    border-color: #ff4d4f;
    box-shadow: ${({ theme }) => `inset 0 0 0 500px ${theme.colors.red20}`};
  }

  ${({ $hasToolTip }) =>
    $hasToolTip &&
    css`
      & .ant-form-item-label > label .ant-form-item-tooltip {
        background: ${({ theme }) => theme.colors.grey60};
        color: ${({ theme }) => theme.colors.white};
        border-radius: 50%;
      }
    `}

  .ant-input-status-error {
    box-shadow: ${({ theme }) => `inset 0 0 0 500px ${theme.colors.red20}`};
  }

  .ant-select-status-error > .ant-select-selector {
    box-shadow: ${({ theme }) => `inset 0 0 0 500px ${theme.colors.red20}`};
  }
`;

export const CustomErrorDisplay = styled.div`
  margin-top: -15px;
`;

export const List = styled.ul`
  padding-left: 20px;
  max-height: 50px;
  overflow-y: scroll;
`;

export const ListItem = styled.li`
  font-size: 10px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.red60};
`;
