import styled from 'styled-components';

export const MinimalInput = styled.div`
  position: relative;

  & .ant-input {
    padding-top: 16px;
    padding-bottom: 8px;
  }

  & .react-tel-input > .ant-input {
    padding-top: 18px;
    padding-bottom: 6px;
    height: 50px;
  }

  & .label {
    font-size: 15px;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 12px;
    top: 12px;
    transition: 0.2s ease all;
    color: ${({ theme }) => theme.colors.grey30};
  }

  & .label-float {
    top: 5px;
    font-size: 10px;
  }
`;
