import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import { hooks, next } from '@vette/frontend-utils';
import { Space } from 'antd';
import { useRouter } from 'next/router';
import React, {
  forwardRef,
  Ref,
  useEffect,
  useCallback,
  useImperativeHandle,
} from 'react';
import { Title } from '../atoms';

import * as S from './styles';

type Props = {
  title: string;
  subtitle?: string | null;
  hasBackButton?: boolean;
  headerActions?: React.ReactNode;
  footer?: React.ReactNode;
  contentStyle?: React.CSSProperties;
  children: React.ReactNode;
  redirectBackRoute?: string;
  allowScroll?: boolean;
  closeDrawerCallback?: () => void;
};

export type PageCardRef = {
  scrollToBottom: () => void;
  scrollToTop: () => void;
};

const COLLAPSE_THRESHOLD = 84;
const COLLAPSE_THRESHOLD_RESERVE = 12;

export const PageCard = forwardRef(
  (
    {
      title,
      subtitle,
      hasBackButton,
      headerActions,
      children,
      footer,
      contentStyle,
      redirectBackRoute,
      allowScroll,
      closeDrawerCallback,
    }: Props,
    ref?: Ref<PageCardRef>
  ) => {
    const router = useRouter();
    const scrollRef = React.useRef<HTMLDivElement>(null);

    const { isAdmin } = hooks.useRole();

    const canGoBack = () => {
      const defaultRoute = isAdmin ? '/dispatching' : '/dashboard';

      const historyLength = window.history.length;
      return historyLength > 1 ? router.back() : router.replace(defaultRoute);
    };

    const inDrawer = typeof closeDrawerCallback === 'function';
    const [showCollapsedHeader, setShowCollapsedHeader] = React.useState(
      !!inDrawer
    );

    const handleScroll = useCallback(() => {
      if (scrollRef.current) {
        const currentScrollY = scrollRef.current.scrollTop;
        // to avoid attaching multiple listeners to scrollRef
        // (react state cannot be used to track the changes here)
        const wentOverThreshold =
          scrollRef.current.classList.contains('over-threshold');

        if (currentScrollY > COLLAPSE_THRESHOLD && !wentOverThreshold) {
          setShowCollapsedHeader(true);
          scrollRef.current.classList.add('over-threshold');
        } else if (
          currentScrollY < COLLAPSE_THRESHOLD - COLLAPSE_THRESHOLD_RESERVE &&
          wentOverThreshold
        ) {
          setShowCollapsedHeader(false);
          scrollRef.current.classList.remove('over-threshold');
        }
      }
    }, []);

    useEffect(() => {
      if (inDrawer) return;
      if (scrollRef.current) {
        scrollRef.current.addEventListener('scroll', handleScroll, {
          passive: true,
        });
      }
    }, [handleScroll, inDrawer]);

    useImperativeHandle(ref, () => ({
      scrollToBottom: () => {
        if (scrollRef.current)
          scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
      },
      scrollToTop: () => {
        if (scrollRef.current) scrollRef.current.scrollTop = 0;
      },
    }));

    const handleRedirectBack = () => {
      redirectBackRoute ? router.replace(redirectBackRoute) : canGoBack();
    };

    return (
      <next.ClientSide>
        <S.Layout
          ref={scrollRef}
          $allowScroll={allowScroll}
          $inDrawer={inDrawer}
        >
          <S.Card $allowScroll={allowScroll} $inDrawer={inDrawer}>
            {title && (
              <S.Header $showBorder={!inDrawer && showCollapsedHeader}>
                <S.HeaderSection $inDrawer={inDrawer}>
                  {hasBackButton && (
                    <S.BackButton
                      actionType="secondary"
                      size="large"
                      onClick={() =>
                        inDrawer ? closeDrawerCallback() : handleRedirectBack()
                      }
                    >
                      {!inDrawer ? <ArrowBackIcon /> : <CloseIcon />}
                    </S.BackButton>
                  )}
                  <Space size={0} direction="vertical">
                    <Title level={showCollapsedHeader ? 2 : 1}>{title}</Title>
                    {subtitle && <Title level={3}>{subtitle}</Title>}
                  </Space>
                </S.HeaderSection>
                {headerActions && (
                  <S.ActionsWrapper>{headerActions}</S.ActionsWrapper>
                )}
              </S.Header>
            )}
            <S.Content style={contentStyle} $allowScroll={allowScroll}>
              {children}
            </S.Content>
            {footer}
          </S.Card>
        </S.Layout>
      </next.ClientSide>
    );
  }
);
