import { mapValues } from 'lodash';

export const recursiveApplyTransformation = (
  /* eslint-disable @typescript-eslint/no-explicit-any */
  value: any,
  transformation: (x: any) => any
): any => {
  /* eslint-enable @typescript-eslint/no-explicit-any */
  if (value == null) return value;
  if (value instanceof File) return value;

  if (typeof value === 'string') {
    return transformation(value);
  }
  if (Array.isArray(value)) {
    return value.map(val => recursiveApplyTransformation(val, transformation));
  }

  if (typeof value === 'object') {
    if (value instanceof Blob || value instanceof File) return value;
    if (value instanceof Date) return value;

    return mapValues(value, val =>
      recursiveApplyTransformation(val, transformation)
    );
  }
  return value;
};
