import React, { useState } from 'react';
import { FormItemProps } from 'antd';
import { helpers } from '@vette/common-utils';
import { Grid } from 'antd';
import { FloatLabel } from '../FloatLabel';
import { theme } from '../../theme';
import { Text } from '../Typography';
import * as S from './styles';

export type CustomErrorType = {
  title?: string;
  errors: string[];
};

type Props = {
  redLabelOnError?: boolean;
  compact?: boolean;
  ignoreNormalize?: boolean;
  customError?: CustomErrorType;
} & FormItemProps;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const recursiveTrimStartValue = (value: any) => {
  return helpers.recursiveApplyTransformation(value, val => val.trimStart());
};

export const FormItem: React.FC<Props> = ({
  ignoreNormalize,
  compact,
  customError,
  redLabelOnError = false,
  ...formItemProps
}) => {
  const { xs } = Grid.useBreakpoint();
  const [currentValue, setCurrentValue] = useState(formItemProps.initialValue);

  const customErrorBox = customError ? (
    <S.CustomErrorDisplay>
      <Text $size={9} $weight={600} $color={theme.colors.red60}>
        {customError.title}
      </Text>
      <S.List>
        {customError.errors.map((e, index) => (
          <S.ListItem key={index}>{e}</S.ListItem>
        ))}
      </S.List>
    </S.CustomErrorDisplay>
  ) : (
    <></>
  );

  if (compact && xs) {
    const { label, ...restFormItemProps } = formItemProps;

    const normalize = (value: string) => {
      const finalValue = !ignoreNormalize
        ? recursiveTrimStartValue(value)
        : value;
      setCurrentValue(finalValue);
      return finalValue;
    };

    return (
      <FloatLabel label={formItemProps.label} value={currentValue}>
        <S.FormItem
          $redLabelOnError={redLabelOnError}
          normalize={normalize}
          {...restFormItemProps}
        />
        {customErrorBox}
      </FloatLabel>
    );
  }

  return (
    <>
      <S.FormItem
        $redLabelOnError={redLabelOnError}
        normalize={!ignoreNormalize ? recursiveTrimStartValue : undefined}
        {...formItemProps}
        $hasToolTip={!!formItemProps.tooltip}
      />
      {customErrorBox}
    </>
  );
};
